<template>
    <div grid-list-xs class="pa-6">
        <v-row v-if="true">
            <v-col cols="6">
                <v-btn x-small color="success" block @click="abre_nueva()">Nueva Empresa</v-btn>
                <h4>Prueba : {{ listafiltrada.filter(item => item.pruebas).length }}</h4>
                <h4>Produccion : {{ listafiltrada.filter(item => !item.pruebas).length }}</h4>
            </v-col>
            <v-col cols="6">
                <v-text-field dense outlined v-model="buscar" append-icon="mdi-magnify" label="BUSCAR"></v-text-field>
            </v-col>
        </v-row>

        <v-simple-table class="elevation-4" fixed-header height="70vh" dense>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">
                            BD
                        </th>
                        <th class="text-left">
                            EMPRESA
                        </th>
                        <th class="text-left">
                            Departamento
                        </th>
                        <th class="text-left">
                            Provincia
                        </th>
                        <th class="text-left">
                            Correo
                        </th>
                        <th class="text-left">
                            Prueba
                        </th>

                        <th class="text-left">
                            Accion
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in listafiltrada" :key="item.id">
                        <td style="font-size:80%;">{{ item.bd }}</td>
                        <td style="font-size:80%;">{{ item.ruc }} - {{ item.name }}</td>
                        <td style="font-size:80%;">{{ item.departamento }}</td>
                        <td style="font-size:80%;">{{ item.provincia }}</td>
                        <td style="font-size:80%;">{{ item.usuario }}</td>

                        <td style="font-size:80%;"> <v-icon v-if="item.pruebas" color="warning">mdi-circle</v-icon>
                            <v-icon v-if="!item.pruebas" color="success">mdi-circle</v-icon>
                        </td>

                        <td style="font-size:80%;">
                            <v-row>
                                <v-col cols="12">
                                    <v-icon color="green" @click="editar_emp(item)">mdi-lead-pencil</v-icon>
                                </v-col>
                            </v-row>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>


        <v-dialog v-model="dialog" max-width="1000px">
            <v-card>


                <v-container>
                    <v-row>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="name" label="Empresa"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="namecomercial" label="Nombre Comercial"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="ruc" type="number" append-icon="mdi-magnify"
                                @click:append="BuscarDocumento()" @keyup.enter="BuscarDocumento()"
                                label="Ruc"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="departamento" label="Departamento"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="provincia" label="Provincia"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="distrito" label="Distrito"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="direccion" label="Direccion"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="ubigeo" label="UBIGEO"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="anexo" label="ANEXO (0000)"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="usuario" label="Usuario"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="clave" label="Clave"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="usuariosol" label="USUARIO SOL"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="clavesol" label="CLAVE SOL"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="nombrefirma" label="Nombre firma"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="passfirma" label="PASS FIRMA"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="PersonaID" label="PersonaID"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="Token" label="Token"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="bd" label="BD"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-checkbox label="Modulo hoteles" v-model="hoteles"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-checkbox label="Modulo Restaurante" v-model="restaurante"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-checkbox label="PRUEBA" v-model="pruebas"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-checkbox label="Caja V2" v-model="caja2"></v-checkbox>
                        </v-col>
                    </v-row>
                </v-container>


                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">
                        Cancel
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="guarda_empresa()">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {
    allEmpresas,
    nuevaEmpresa,
    eliminaEmpresa,
    actualizaEmpresa,
    crearCampo,
    allProductoOtraBase,
    nuevoUsuario,
    allCategorias,
    crearCampoconfiguracion,
    crearCampoCATEGORIAS,
    grabaactualizadialogo,
    allComprobantesCabeceraOtraBase,
    nuevoComprobantesCabeceraSerie,
    elimina_all_flujo_bd
} from '../db'
import firebase from 'firebase'
import axios from "axios"
import store from '@/store/index'
export default {
    data: () => ({
        buscar: '',
        dialog: false,
        desserts: [],
        name: '',
        namecomercial: '',
        ruc: '',
        departamento: '',
        provincia: '',
        distrito: '',
        direccion: '',
        ubigeo: '',
        anexo: '',
        usuario: '',
        clave: '',
        usuariosol: '',
        clavesol: '',
        nombrefirma: '',
        passfirma: '',
        PersonaID: '',
        Token: '',
        bd: '',
        restaurante: false,
        hoteles: false,
        pruebas: false,
        caja2: true,
        editar: false,
    }),
    mounted() {
        allEmpresas().on("value", this.onDataChange);
    },
    beforeDestroy() {
        allEmpresas().off("value", this.onDataChange);
    },

    computed: {
        listafiltrada() {
            return this.desserts.filter((item) => (item.bd + item.ruc + item.name + item.usuario + item.departamento + item.provincia)
                .toLowerCase().includes(this.buscar.toLowerCase()))
        }

    },


    methods: {
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let key = item.key;
                let data = item.val();
                data.key = key
                array.push(data);
            });
            this.desserts = array;
        },
        abre_nueva() {
            this.editar = false
            this.name = ''
            this.namecomercial = ''
            this.ruc = ''
            this.departamento = ''
            this.provincia = ''
            this.distrito = ''
            this.direccion = ''
            this.ubigeo = ''
            this.anexo = '0000'
            this.usuario = ''
            this.clave = 'admin1234'
            this.usuariosol = 'DOMOTICA'
            this.clavesol = 'Domotica1234'
            this.nombrefirma = 'demo.pfx'
            this.passfirma = 'Domotica1234'
            this.PersonaID = ''
            this.Token = ''
            this.bd = ''
            this.restaurante = false
            this.hoteles = false
            this.pruebas = false
            this.caja2 = true
            this.dialog = true
        },
        editar_emp(data) {
            this.editar = true
            this.name = data.name
            this.namecomercial = data.namecomercial
            this.ruc = data.ruc
            this.departamento = data.departamento
            this.provincia = data.provincia
            this.distrito = data.distrito
            this.direccion = data.direccion
            this.ubigeo = data.ubigeo
            this.anexo = data.anexo
            this.usuario = data.usuario
            this.clave = data.clave
            this.usuariosol = data.usuariosol
            this.clavesol = data.clavesol
            this.nombrefirma = data.nombrefirma
            this.passfirma = data.passfirma
            this.PersonaID = data.PersonaID
            this.Token = data.Token
            this.bd = data.bd
            this.restaurante = data.restaurante
            this.hoteles = data.hoteles || false
            this.pruebas = data.pruebas
            this.caja2 = data.caja2
            this.dialog = true
        },
        async guarda_empresa() {
            var array = {
                name: this.name,
                namecomercial: this.namecomercial,
                ruc: this.ruc,
                departamento: this.departamento,
                provincia: this.provincia,
                distrito: this.distrito,
                direccion: this.direccion,
                ubigeo: this.ubigeo,
                anexo: this.anexo,
                usuario: this.usuario,
                clave: this.clave,
                usuariosol: this.usuariosol,
                clavesol: this.clavesol,
                nombrefirma: this.nombrefirma,
                passfirma: this.passfirma,
                PersonaID: this.PersonaID,
                Token: this.Token,
                bd: this.bd,
                restaurante: this.restaurante,
                hoteles: this.hoteles || false,
                pruebas: this.pruebas,
                caja2: this.caja2
            }
            if (this.editar) {
                await nuevaEmpresa(array.bd, array)
                this.dialog = false
            } else {
                if (this.bd == '') {
                    alert('BD NO PUEDE SER VACIA')
                    return
                }
                var a = this.desserts.find(item => item.bd == this.bd)
                if (a != undefined) {
                    alert('BD OCUPADA REVISAR')
                    return
                }
                store.commit("dialogoprogress")
                await nuevaEmpresa(array.bd, array)
                await this.crearUsuario()
                this.crearUsuarios_()
            }

        },
        async crearUsuario() {
            var token = this.create_UUID()
            var array = {
                token: token,
                nombre: this.namecomercial,
                correo: this.usuario,
                pass: this.clave,
                bd: this.bd,
                moduloempresa: true,
                modulokardex: true,
                modulosunat: true,
                moduloproductos: true,
                productos_visualiza_stock: true,
                moduloclientes: true,
                modulomesas: true,
                modulococina: true,
                limpia_comanda: true,
                trans_comanda: true,
                unir_comanda: true,
                editar_comanda: true,
                modulocaja: true,
                modulo_empleados: true,
                caja_edita_cantidad: true,
                caja_edita_precio: true,
                moduloreportes: true,
            }
            await nuevoUsuario(array.token, array)
        },
        crearUsuarios_() {
            firebase.auth().createUserWithEmailAndPassword(this.usuario, this.clave)
                .then(user => {
                    store.commit("dialogoprogress")
                    this.$router.push({
                        name: 'panel'
                    })
                }).catch(error => {
                    store.commit("dialogoprogress")
                    alert('error interno, comuniquese con administrador')
                })

        },
        create_UUID() {
            var dt = new Date().getTime();
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid.substring(20);
        },
        BuscarDocumento() {
            if (this.ruc.length == 11) {
                store.commit("dialogoprogress")
                var self = this,
                    token = '80a4a1c5f2e97c2d78fcd5074cd64ff0a29007ef91880ad2c214af67a084433d'
                axios
                    .get('https://apiperu.dev/api/ruc/' + this.ruc, {
                        headers: {
                            Content_Type: 'application/json',
                            authorization: ' Bearer ' + token
                        }
                    })
                    .then(response => (this.info = response.data,
                        self.llenardatos(response.data.data),
                        store.commit("dialogoprogress")
                    ))
                    .catch(error => {
                        store.commit("dialogoprogress"),
                            store.commit('dialogosnackbar', "DOCUMENTO INVALIDO")
                    })
            } else {
                store.commit('dialogosnackbar', "DOCUMENTO INVALIDO")
            }
        },
        llenardatos(data) {
            this.name = data.nombre_o_razon_social
            this.direccion = data.direccion
            this.departamento = data.departamento
            this.provincia = data.provincia
            this.distrito = data.distrito
            this.ubigeo = data.ubigeo_sunat

        },
    },
}
</script>
