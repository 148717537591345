<template>
    <v-container>
        <v-row>
            <v-col cols="6">
                <v-select outlined dense v-model="categoria" :items="categories" label="ZONA"></v-select>
            </v-col>
            <v-col cols="6">
                <v-menu offset-y block>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" block dark v-bind="attrs" v-on="on">
                            OPCIONES
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="dial_gest = !dial_gest">
                            <v-list-item-title>Gestion Habit.</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-for="(room, index) in rooms" :key="index" cols="4" class="pa-1" md="2" sm="3" xs="4">
                <v-card :color="getRoomColor(room.estado)" dark>
                    <v-card-title>{{ room.nombre }}</v-card-title>
                    <v-card-subtitle>{{ room.tipo }}</v-card-subtitle>
                    <v-card-actions>
                        <v-btn small text color="white" @click="viewDetails(room)">
                            Ver Detalles
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="dial_detalle" max-width="490">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_detalle = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <h4>Habitacion : {{ item_selecto.nombre }}</h4>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-2s">
                <v-card-text>
                    <v-list dense>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Servicio</v-list-item-title>
                                <v-list-item-subtitle>{{ item_selecto.detalles.detalle_venta }} - S/ {{
                                    item_selecto.costo
                                }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item class="mb-n3">
                            <v-list-item-content>
                                <v-list-item-title>Cliente</v-list-item-title>
                                <v-list-item-subtitle>{{ item_selecto.detalles.documento_cliente }} - {{
                                    item_selecto.detalles.numero_cliente }} <br> {{ item_selecto.detalles.nombre_cliente
                                    }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="mb-n1">
                            <v-list-item-content>
                                <v-list-item-title>Acompañantes</v-list-item-title>
                                <v-list-item-subtitle>-</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Fecha Entrada</v-list-item-title>
                                <v-list-item-subtitle>{{ conviertefecha(item_selecto.detalles.fecha_entrada)
                                    }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="item_selecto.detalles.fecha_salida">
                            <v-list-item-content>
                                <v-list-item-title>Fecha Salida</v-list-item-title>
                                <v-list-item-subtitle>{{ formatFecha(item_selecto.detalles.fecha_salida)
                                    }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Observaciones</v-list-item-title>
                                <v-list-item-subtitle>{{ item_selecto.detalles.observacion_cliente || 'Ninguna'
                                    }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-row class="mt-4 mb-6">
                        <v-col cols="6" class="pa-1 mx-auto " md="6" sm="6" xs="6">
                            <v-card @click.prevent="dial_consumo = true">
                                <v-container>
                                    <v-img class="mx-auto" height="45" width="45" src="/food.png"></v-img>
                                    <h5 block class="text-center pa-1">Consumo</h5>
                                </v-container>
                            </v-card>
                        </v-col>
                        <v-col cols="6" class="pa-1 mx-auto " md="6" sm="6" xs="6">
                            <v-card @click.prevent="cobro()">
                                <v-container>
                                    <v-img class="mx-auto" height="45" width="45" src="/cash.png"></v-img>
                                    <h5 block class="text-center pa-1">Cobrar</h5>
                                </v-container>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>

            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_limpieza" max-width="490">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_limpieza = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <h4>Habitacion : {{ item_selec.nombre }}</h4>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-2">
                <v-card-text>
                    <v-row class="mt-2">
                        <v-col cols="6" class="pa-1 mx-auto " md="6" sm="6" xs="6">
                            <v-card @click.prevent="cambia_estado('Disponible')">
                                <v-container>
                                    <v-img class="mx-auto" height="50" width="50" src="/clean.png"></v-img>
                                    <h5 block class="text-center pa-1">Limpiar</h5>
                                </v-container>
                            </v-card>
                        </v-col>
                        <v-col cols="6" class="pa-1 mx-auto " md="6" sm="6" xs="6">
                            <v-card @click.prevent="cambia_estado('Mantenimiento')">
                                <v-container>
                                    <v-img class="mx-auto" height="50" width="50" src="/tools.png"></v-img>
                                    <h5 block class="text-center pa-1">Mantenimiento</h5>
                                </v-container>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>

            </v-card>
        </v-dialog>
        <Gestion_hab v-if="dial_gest" @cierra="dial_gest = false"></Gestion_hab>
        <Registro_hab :data="roomData" v-if="dial_reg" @cierra="dial_reg = false"></Registro_hab>
        <dial_cob :data="item_selecto" v-if="dial_cobro" @cierra="dial_cobro = false" @terminar='termina_venta($event)'>
        </dial_cob>
        <dial_consu :data="item_selecto" v-if="dial_consumo" @cierra="dial_consumo = false">
        </dial_consu>
        <imprime v-if="genera_pdf" :data="cabecera_cobro" @cierra="genera_pdf = false" />
    </v-container>
</template>

<script>
import {
    all_habitaciones,
    ver_registro,
    nueva_habitacion
} from '../../db'
import imprime from '@/components/dialogos/dialog_imprime'
import moment from 'moment'
import store from '@/store/index'
import axios from "axios"
import Gestion_hab from './gestion_hab.vue';
import Registro_hab from './dial_registra.vue'
import dial_cob from './dial_cobro.vue'
import dial_consu from './dial_consumo.vue'
export default {
    components: {
        Gestion_hab,
        Registro_hab,
        dial_cob,
        dial_consu,
        imprime
    },
    data() {
        return {
            dial_limpieza: false,
            genera_pdf: false,
            cabecera_cobro: '',
            dial_consumo: false,
            dial_detalle: false,
            dial_gest: false,
            dial_reg: false,
            dial_cobro: false,
            categoria: null,
            roomData: null,
            rooms: [],
            item_selec: [],
            item_selecto: {
                categoria: '',
                estado: '',
                nombre: '',
                tipo: '',
                costo: '',
                detalles: {
                    nombre_cliente: '',
                    documento_cliente: '',
                    numero_cliente: '',
                    habitacion: '',
                    detalle_venta: null,
                    fecha_entrada: null,
                    fecha_salida: null,
                    observacion_cliente: '',
                },
            },
        };
    },
    mounted() {
        all_habitaciones().on("value", this.onDataChange);
    },
    beforeDestroy() {
        all_habitaciones().off("value", this.onDataChange);
    },
    computed: {
        groupedRooms() {
            const groups = {};
            this.rooms.forEach((room) => {
                if (!groups[room.categoria]) {
                    groups[room.categoria] = {
                        nombre: room.categoria,
                        rooms: []
                    };
                }
                groups[room.categoria].rooms.push(room);
            });
            return Object.values(groups);
        },
        categories() {
            // Devuelve solo las categorías únicas
            return [...new Set(this.rooms.map((room) => room.categoria))];
        },
    },
    methods: {
        async cambia_estado(val) {
            store.commit("dialogoprogress")
            await nueva_habitacion(this.item_selec.nombre + '/estado', val)
            this.dial_limpieza = false
            store.commit("dialogoprogress")
        },
        async termina_venta(val) {
            await nueva_habitacion(this.item_selecto.nombre + '/estado', 'Limpieza')
            this.cabecera_cobro = val
            this.genera_pdf = true
            this.dial_cobro = false
            this.dial_detalle = false
        },
        cobro() {
            this.dial_cobro = true
        },
        conviertefecha(date) {
            return moment.unix(date).format('DD/MM/YYYY hh:mm A')
        },
        formatFecha(timestamp) {
            if (!timestamp) return "No registrada";
            const date = new Date(timestamp * 1000); // Convertir de segundos a milisegundos
            return date.toLocaleDateString("es-PE", {
                year: "numeric",
                month: "long",
                day: "numeric",
            });
        },
        onDataChange(items) {
            this.rooms = []
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                if (data.estado == 'PENDIENTE') {
                    data.color = '#46FF00'
                } else {
                    data.color = '#FF0000'
                }
                array.push(data);
            });
            this.rooms = array;
        },
        // Retorna el color dependiendo del estado de la habitación
        getRoomColor(estado) {
            switch (estado) {
                case 'Disponible':
                    return 'green';
                case 'Ocupada':
                    return 'red';
                case 'Limpieza':
                    return 'orange';
                case 'Mantenimiento':
                    return 'blue';
                default:
                    return 'grey';
            }
        },
        // Acción al ver detalles de una habitación
        async viewDetails(room) {
            if (room.estado == 'Disponible') {
                this.roomData = room
                this.dial_reg = true
            }
            if (room.estado == 'Ocupada') {
                store.commit("dialogoprogress")
                this.item_selecto = room
                console.log(room.detalle)
                var snap = await ver_registro(room.detalle).once('value')
                if (snap.exists()) {
                    this.item_selecto.detalles = snap.val()
                    this.item_selecto.id_detalle = snap.val().fecha_entrada
                }
                store.commit("dialogoprogress")
                this.dial_detalle = true
            }

            if (room.estado == 'Limpieza') {
                console.log(room)
                this.item_selec = room
                this.dial_limpieza = true
            }
            if (room.estado == 'Mantenimiento') {
                console.log(room)
                this.item_selec = room
                this.dial_limpieza = true
            }
        },

    },
};
</script>

<style scoped>
.text-center {
    text-align: center;
}
</style>
