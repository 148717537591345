<template>
    <v-dialog v-model="dial" max-width="550">
        <div>
            <v-system-bar class="" dense window dark height="30">
                <v-icon large color="red" @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <h3>Total = S/.{{ total }}</h3>
                <v-spacer></v-spacer>
            </v-system-bar>
        </div>

        <v-card class="pa-3">
            <v-radio-group dense class="mt-n1 mx-auto " v-model="tipocomprobante" row>
                <v-radio label="Nota" value="T"></v-radio>
                <v-radio label="Boleta" value="B"></v-radio>
                <v-radio label="Factura" value="F"></v-radio>
            </v-radio-group>
            <v-row class="mt-n4" dense>
                <v-col cols="4" xs="4">
                    <v-select outlined dense v-model="documento" :items="documentos" menu-props="auto" hide-details
                        label="Tipo Doc"></v-select>
                </v-col>
                <v-col cols="8" xs="8">
                    <v-text-field outlined dense type="number" v-model="numero" label="Numero" append-icon="mdi-magnify"
                        :autofocus="!$store.state.esmovil" @click:append="BuscarDocumento()"
                        @keyup.enter="BuscarDocumento()"></v-text-field>
                </v-col>
            </v-row>
            <v-text-field class="mt-n5" outlined dense v-model="nombreCompleto" label="Cliente"></v-text-field>
            <v-simple-table fixed-header dense height="28vh" class="mt-n5">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Descripcion
                            </th>
                            <th class="text-left">
                                Precio
                            </th>
                            <th class="text-left">
                                Total
                            </th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr v-for="item in listaproductos" :key="item.id" @click.prevent="editaProducto(item)">
                            <td style="max-width: 175px;font-size:75%;"> <strong class="red--text"> {{ item.cantidad }}
                                    x</strong> {{ item.nombre }}
                                <strong class="red--text" v-if="item.operacion == 'GRATUITA'"> -
                                    Gratuita</strong>
                            </td>
                            <td style="max-width: 175px;font-size:75%;">S/.{{ item.precio }}</td>
                            <td style="max-width: 175px;font-size:75%;">S/.{{ redondear((item.precio * item.cantidad))
                                }}</td>
                        </tr>
                    </tbody>
                </template>

            </v-simple-table>
            <v-row class="">
                <v-col cols="4">
                    <v-btn block color="primary" @click="cobrar()" small>
                        EFECTIVO
                    </v-btn>
                </v-col>
                <v-col cols="4">
                    <v-btn :disabled="nombreCompleto == '' || numero == ''" block color="error" @click="cobroCredito()"
                        small v-if="$store.state.configuracion.creditofactura">
                        Credito
                    </v-btn>
                </v-col>
                <v-col cols="4">
                    <v-btn block color="warning" @click="otros()" small>
                        OTROS
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
        <v-dialog v-model="dialogoProducto" max-width="390">
            <div>
                <v-system-bar window dark>
                    <v-icon large color="red" @click="dialogoProducto = false">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">

                <v-row class="mx-auto mt-4 text-center" dense v-if="$store.state.permisos.caja_edita_cantidad">

                    <v-col cols="4" xs="4">
                        <v-icon @click="suma()" color="green">mdi-plus</v-icon>
                    </v-col>

                    <v-col cols="4" xs="4">
                        <v-text-field dense @keyup.enter="grabaEdita()" type="number" outlined v-model="cantidadEdita"
                            label="Cantidad"></v-text-field>
                    </v-col>
                    <v-col cols="4" xs="4">
                        <v-icon @click="resta()" color="red">mdi-minus</v-icon>
                    </v-col>

                </v-row>
                <v-row class="mx-auto text-center mt-n2" dense>
                    <v-col cols="12">
                        <v-textarea dense class="mt-n2" outlined v-model="nombreEdita" auto-grow filled
                            color="deep-purple" label="Descripcion" rows="1"></v-textarea>
                    </v-col>
                </v-row>
                <v-row class="mx-auto text-center mt-n3" dense>
                    <v-col @focus="$event.target.select()" cols="6" v-if="$store.state.permisos.caja_edita_precio">

                        <v-text-field :disabled="!$store.state.permisos.moduloproductos" outlined dense
                            @keyup.enter="grabaEdita()" type="number" v-model="precioedita"
                            label="Precio"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-if="false" @focus="$event.target.select()" outlined dense
                            @keyup.enter="grabaEdita()" type="number" v-model="preciodescuento"
                            label="Descuento"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mx-auto mt-n3 text-center" dense v-if="false">
                    <v-col cols="6" xs="6">
                        <v-text-field @focus="$event.target.select()" outlined dense @keyup.enter="grabaEdita()"
                            type="number" v-model="porcentaje" append-icon="%" label="Descuento %"></v-text-field>
                    </v-col>
                    <v-col cols="6" xs="6">
                        <h4 class="pa-3">S/.{{ conversion }}</h4>
                    </v-col>
                </v-row>

                <v-card-actions class="mt-n6">

                    <v-btn color="red darken-1" text @click="eliminaedita()">
                        Elimina
                    </v-btn>

                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="grabaEdita()">
                        Graba
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_pagos" max-width="490" transition="dialog-bottom-transition">
            <v-card class="pa-3">
                {{ valida_modo_pago }}
                <div class="mt-6">
                    <v-row class="mt-n3" dense v-for="item in modopagos" :key="item.modo">
                        <v-col cols="12">
                            <v-row dense no-gutters>
                                <v-col cols="1">

                                </v-col>
                                <v-col cols="2">
                                    <v-img class="ml-n4" height="35" width="35" :src="busca_ico(item.nombre)"
                                        @click="cambia_modo_pago(item)"></v-img>
                                </v-col>
                                <v-col cols="9">
                                    <v-text-field class="ml-n10" :autofocus="item.nombre == 'EFECTIVO'" prefix="S/."
                                        outlined dense v-model="item.monto" type="number"
                                        :label="item.nombre"></v-text-field>
                                </v-col>
                            </v-row>

                        </v-col>

                    </v-row>
                </div>
                <v-btn block color="error" @click="cobrar()" small>
                    COBRAR
                </v-btn>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
import {
    guard_registro,
    nuevoCliente,
    nueva_habitacion,
    obtenContador
} from '../../db'
import {
    cobrar_js,
    procesar_items
} from '@/funciones_generales'
import moment from 'moment'
import store from '@/store/index'
import axios from "axios"
export default {
    props: {
        data: [],
    },
    data() {
        return {
            date: moment(String(new Date)).format('YYYY-MM-DD'),
            dialogoProducto: false,
            dial_pagos: false,
            listaproductos: [],
            dial: false,
            cantidadEdita: '',
            preciodescuento: '',
            precioedita: '',
            nombreEdita: '',
            porcentaje: 0,
            tipocomprobante: 'T',
            documentos: ['DNI', 'RUC', 'Pasaporte', 'Carnet de Extranjeria'],
            documento: 'DNI',
            numero: '',
            nombreCompleto: '',
            modopagos: [],
            total: '',
            cuotasCredito: [],
        };
    },

    created() {
        this.modopagos = []
        var modos = store.state.modopagos
        for (var i = 0; i < modos.length; i++) {
            var data = modos[i]
            var mont = ''
            this.modopagos.push({
                nombre: data,
                monto: mont
            })
        }
        this.tipocomprobante = store.state.configuracion.defecto
        if (this.data.detalles.consumo) {
            this.listaproductos = JSON.parse(JSON.stringify(this.data.detalles.consumo));
        }
        console.log(this.data.detalles)
        this.listaproductos.unshift({
            id: '0001',
            cantidad: 1,
            nombre: this.data.detalles.detalle_venta,
            precio: this.data.detalles.costo,
            operacion: 'GRAVADA',
            medida: 'UNIDAD',
            tipoproducto: 'SERVICIO'
        })
        this.nombreCompleto = this.data.detalles.nombre_cliente
        this.documento = this.data.detalles.documento_cliente
        this.numero = this.data.detalles.numero_cliente
        this.dial = true
    },
    mounted() {

    },
    computed: {
        cuadravuelto() {
            var resta = 0
            resta = this.paravuelto - this.total
            if (resta < 0) {
                return "ES MENOR AL TOTAL"
            } else {
                return "VUELTO : S/." + resta.toFixed(2)
            }
        },
        valida_modo_pago() {
            this.calcularTotal()
            var a = this.modopagos.find(id => String(id.nombre) == String('EFECTIVO'))
            var resta = this.total
            for (var i = 1; i < this.modopagos.length; i++) {
                resta = resta - this.modopagos[i].monto
            }

            if (resta < 0) {
                store.commit('dialogosnackbar', 'MONTOS NO COINCIDEN')
            } else {
                this.modopagos[0].monto = resta
            }
            return
        },
    },
    methods: {
        calcularTotal() {
            let total = 0;
            this.listaproductos.forEach(item => {
                total += item.precio * item.cantidad;
            });
            this.total = total.toFixed(2);  // Redondear a dos decimales
        },
        verdate() {
            var fecha = moment(String(this.date)) / 1000
            if (moment(String(new Date())).format('YYYY-MM-DD') == moment(String(this.date)).format('YYYY-MM-DD')) {
                fecha = moment().unix()
            }
            return fecha
        },
        async cobrar() {
            if (this.valida_pagos() != parseFloat(this.total)) {
                alert('Debe ingresar monto correcto')
                return
            }
            if (this.tipocomprobante == "F" && this.numero.length != 11 ||
                this.tipocomprobante == "F" && this.nombreCompleto == '' ||
                this.documento == 'RUC' && this.numero.length != 11) {
                alert('DEBE CONSIDERAR DATOS DEL CLIENTE')
                return
            }
            store.commit("dialogoprogress")
            var resp = await procesar_items(this.listaproductos)
            var cabecera = resp[0]
            var items = resp[1]
            var fechahoy = this.verdate()
            cabecera.fecha = fechahoy
            cabecera.vencimientoDoc = fechahoy
            cabecera.total = this.total
            cabecera.descuentos = 0

            let snapshot = await obtenContador().once("value")
            var contadores = snapshot.val()
            var auto = ""
            if (this.documento == "DNI") {
                var doccliente = "1" // 6 ruc --4 carnet --7 pasaporte -- 1 DNI
            }
            if (this.documento == "RUC") {
                var doccliente = "6" // 6 ruc --4 carnet --7 pasaporte -- 1 DNI
            }
            if (this.documento == "Pasaporte") {
                var doccliente = "7" // 6 ruc --4 carnet --7 pasaporte -- 1 DNI
            }
            if (this.documento == "Carnet de Extranjeria") {
                var doccliente = "4" // 6 ruc --4 carnet --7 pasaporte -- 1 DNI
            }
            if (this.tipocomprobante == "B") { //Catálogo No. 01: Código de Tipo de documento
                var cod_comprobante = '03' //01-factura -- 03-boleta -- 07-notaCred -- 08-notadebit -- 
                var serie = store.state.seriesdocumentos.boleta
                var correlativo = contadores.ordenboleta
            }
            if (this.tipocomprobante == "F") { //Catálogo No. 01: Código de Tipo de documento 
                var cod_comprobante = '01' //01-factura -- 03-boleta -- 07-notaCred -- 08-notadebit -- 
                var serie = store.state.seriesdocumentos.factura
                var correlativo = contadores.ordenfactura
            }
            if (this.tipocomprobante == "T") {
                var cod_comprobante = '00'
                var serie = store.state.seriesdocumentos.ticket
                var correlativo = contadores.ordenticket
                cabecera.estado = 'aprobado'
                auto = "NO"
            }
            if (this.nombreCompleto == '') {
                this.nombreCompleto = 'CLIENTES VARIOS'
            }
            if (this.numero == '') {
                this.numero = '00000000'
                this.documento = "DNI"
                var doccliente = "1"
            }
            cabecera.forma_pago = "Contado"
            cabecera.cuotas = ''
            if (this.cuotasCredito != '') {
                var vencimientodoc = moment(String(this.cuotasCredito[this.cuotasCredito.length - 1].vencimiento)) / 1000
                cabecera.vencimientoDoc = vencimientodoc
                cabecera.cuotas = this.cuotasCredito
                cabecera.forma_pago = "Credito"
            }

            cabecera.serie = serie
            cabecera.correlativoDocEmitido = correlativo
            cabecera.numeracion = serie + '-' + correlativo
            cabecera.tipoDocumento = this.documento
            cabecera.cod_tipoDocumento = doccliente
            cabecera.dni = this.numero
            cabecera.cliente = this.nombreCompleto
            cabecera.direccion = this.direccion || ''
            cabecera.observacion = this.observacion || ''
            cabecera.tipocomprobante = this.tipocomprobante
            cabecera.cod_comprobante = cod_comprobante
            cabecera.automata = auto
            cabecera.modopago = this.modopagos
            var arrayCabecera = cabecera
            var array_item = items
            var array = {
                arrayCabecera: arrayCabecera,
                array_item: array_item,
            }

            if (arrayCabecera.tipocomprobante != 'T') {
                //   enviaDocumentoApiSunat(arrayCabecera, array_item)
            }
            // await cobrar_js(arrayCabecera, array_item)
            if (cabecera.forma_pago == "Credito") {
                await this.genera_credito(arrayCabecera)
            }
            console.log(cabecera)
            await cobrar_js(array.arrayCabecera, array.array_item)
            store.commit("dialogoprogress")
            this.$emit('terminar', arrayCabecera)
        },
        valida_pagos() {
            var suma = 0
            for (var i = 0; i < this.modopagos.length; i++) {
                var data = this.modopagos[i]
                if (data.monto != '') {
                    suma = suma + parseFloat(data.monto)
                }
            }
            return suma
        },
        otros() {
            this.dial_pagos = true
        },
        grabaEdita() {
            if (this.cantidadEdita == '' || this.cantidadEdita == 0) {
                alert('ingrese cantidad valida')
                return
            }
            if (this.precioedita == 0) {
                alert("Precio no puede ser 0")
                return
            }
            console.log(this.precioedita, this.preciodescuento)
            if (parseFloat(this.precioedita) <= parseFloat(this.preciodescuento)) {
                alert("Precio no puede ser menor/igual a 0")
                return
            }
            if (this.item_selecto.controstock) {
                var producto = store.state.productos.find(item => item.id == this.item_selecto.id)
                if (Number(producto.stock) < Number(this.cantidadEdita)) {
                    alert('Producto sin Stock')
                    return
                }
            }
            if (this.porcentaje != '' && this.porcentaje != 0) {
                this.preciodescuento = this.conversion
            }
            var pos = this.listaproductos.map(e => e.uuid).indexOf(this.item_selecto.uuid)
            this.listaproductos[pos].nombre = this.nombreEdita
            this.listaproductos[pos].cantidad = Number(this.cantidadEdita)
            this.listaproductos[pos].precio = this.precioedita
            this.listaproductos[pos].preciodescuento = this.preciodescuento
            this.dialogoProducto = false
        },
        suma() {
            if (this.item_selecto.controstock) {
                var producto = store.state.productos.find(item => item.id == this.item_selecto.id)
                if (producto.stock <= this.cantidadEdita) {
                    alert('Producto sin Stock')
                    return
                }
            }
            this.cantidadEdita = parseInt(this.cantidadEdita) + 1
        },
        resta() {
            if (this.cantidadEdita > 1) {
                this.cantidadEdita = parseInt(this.cantidadEdita) - 1
            }
        },
        eliminaedita() {
            if (this.id_comanda != '') {
                this.id_comanda = ''
                this.es_delivery = false
                this.listaproductos = []
            }
            var pos = this.listaproductos.map(e => e.uuid).indexOf(this.item_selecto.uuid)
            this.listaproductos.splice(pos, 1)
            this.dialogoProducto = false
        },
        editaProducto(val) {
            this.item_selecto = val
            this.cantidadEdita = val.cantidad
            this.nombreEdita = val.nombre
            this.precioedita = val.precio
            this.preciodescuento = val.preciodescuento
            this.dialogoProducto = true
        },
        redondear(valor) {
            return parseFloat(valor).toFixed(store.state.configuracion.decimal)
        },
        busca_ico(data) {
            var iconos = store.state.iconos_pagos.find(item => item.nombre == data)
            return iconos.icono
        },
        cambia_modo_pago(val) {
            for (var i = 0; i < this.modopagos.length; i++) {
                this.modopagos[i].monto = ''
            }
            var pos = this.modopagos.map(e => e.nombre).indexOf(val.nombre)
            this.modopagos[pos].monto = this.total
        },
        cierra() {
            this.$emit('cierra', false);
        }
    },
};
</script>

<style scoped>
.text-center {
    text-align: center;
}

.group-header {
    background-color: #f5f5f5;
    text-align: left;
    padding: 10px;
}
</style>
