<template>
    <v-dialog v-model="dial" max-width="550" persistent>
        <div>
            <v-system-bar class="" dense window dark height="40">
                <v-icon large color="red" @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <h4>Habitacion {{ data.nombre }}</h4>
                <v-spacer></v-spacer>
                <v-icon color="green" large @click="guardar_hab()">mdi-content-save</v-icon>
            </v-system-bar>
        </div>

        <v-card class="pa-3">

            <v-row class="mt-n2" dense>
                <v-col cols="6">
                    <v-text-field disabled type="date" outlined dense v-model="date" label="Entrada"></v-text-field>
                </v-col>
                <v-col cols="6" xs="6">
                    <v-text-field disabled outlined dense v-model="date_hora" label="Hora"></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mt-n6" dense>
                <v-col cols="6">
                    <v-select outlined dense v-model="documento_cliente" :items="documentos" menu-props="auto"
                        hide-details label="Tipo Doc"></v-select>
                </v-col>
                <v-col cols="6">
                    <v-text-field outlined type="number" dense v-model="numero_cliente" label="Documento"
                        append-icon="mdi-magnify" @click:append="BuscarDocumento(1)"
                        @keyup.enter="BuscarDocumento(1)"></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mt-n6" dense>
                <v-col cols="12">
                    <v-text-field outlined dense v-model="nombre_cliente" label="CLIENTE"></v-text-field>
                </v-col>
            </v-row>


            <v-row class="mt-n6" dense>
                <v-col cols="12">
                    <v-textarea outlined dense v-model="detalle_venta" auto-grow filled label="Detalle Venta"
                        rows="1"></v-textarea>
                </v-col>
            </v-row>
            <v-row class="mt-n6" dense>
                <v-col cols="6">
                    <v-text-field type="number" outlined dense v-model="costo" label="Costo S/"></v-text-field>
                </v-col>
            </v-row>
            <h4 class="text-center mt-n4">Añardir Personas <v-icon @click="dialog_agrega_acom = true"
                    color="blue">mdi-plus</v-icon>
            </h4>

            <v-simple-table fixed-header height="150px" dense v-if="arrayintegrantes.length > 0">
                <thead>

                    <tr>
                        <th class="text-left">
                            Numero
                        </th>
                        <th class="text-left">
                            Nombre
                        </th>
                        <th class="text-left">
                            Accion
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in arrayintegrantes" :key="item.id">
                        <td colspan="2">
                            <v-row class="mt-1" dense>
                                <v-col cols="6">
                                    {{ item.numero_acom }}
                                </v-col>
                                <v-col cols="6">
                                    {{ item.nombre_acom }}
                                </v-col>
                            </v-row>
                        </td>
                        <td width="150">
                            <v-row>
                                <v-col cols="12">
                                    <v-icon color="red" @click="elimina_acom(item)">mdi-delete</v-icon>
                                </v-col>
                            </v-row>
                        </td>
                    </tr>
                </tbody>

            </v-simple-table>
            <v-row class="mt-6" dense>
                <v-col cols="12">
                    <v-textarea outlined dense v-model="observacion_cliente" auto-grow filled label="Observacion"
                        rows="1"></v-textarea>
                </v-col>
            </v-row>
            <v-card-actions>

                <v-spacer></v-spacer>
                <v-btn dense class="" color="green darken-1" text @click="guardar_hab()">
                    Check-In
                </v-btn>
            </v-card-actions>

            <v-dialog v-model="dialog_agrega_acom" max-width="490">
                <div>
                    <v-system-bar window dark>
                        <v-icon @click="dialog_agrega_acom = false">mdi-close</v-icon>
                    </v-system-bar>
                </div>
                <v-card class="pa-3">
                    <v-row class="mt-n1" dense>
                        <v-col cols="6">
                            <v-select outlined dense v-model="documento_acom" :items="documentos" menu-props="auto"
                                hide-details label="Tipo Doc"></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field outlined type="number" dense v-model="numero_acom" label="Documento"
                                append-icon="mdi-magnify" @click:append="BuscarDocumento(2)"
                                @keyup.enter="BuscarDocumento(2)"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-text-field class="mt-n4" outlined dense v-model="nombre_acom" label="CLIENTE"></v-text-field>
                    <v-card-actions>

                        <v-spacer></v-spacer>
                        <v-btn dense class="" color="green darken-1" text @click="agrega_acom()">
                            AGREGAR
                        </v-btn>
                    </v-card-actions>
                </v-card>

            </v-dialog>
        </v-card>
    </v-dialog>
</template>

<script>
import {
    guard_registro,
    nuevoCliente,
    nueva_habitacion
} from '../../db'

import moment from 'moment'
import store from '@/store/index'
import axios from "axios"
export default {
    props: {
        data: [],
    },
    data() {
        return {
            dialog_agrega_acom: false,
            dial: false,
            date: moment(String(new Date)).format('YYYY-MM-DD'),
            date_hora: '',
            documentos: [
                'DNI',
                'RUC',
                'Pasaporte',
                'Carnet de Extranjeria'
            ],
            numero_cliente: '',
            nombre_cliente: '',
            documento_cliente: 'DNI',
            observacion_cliente: '',
            numero_acom: '',
            nombre_acom: '',
            documento_acom: 'DNI',
            arrayintegrantes: [],
            costo: '',
            detalle_venta: 'SERVICIO DE ALOJAMIENTO',
        };
    },

    created() {
        this.costo = this.data.costo
        this.dial = true
    },
    mounted() {

        setInterval(this.actualizaTiempo, 1000);
    },
    methods: {
        async guardar_hab() {
            if (this.detalle_venta == '') {
                alert('Ingrese una descripcion a la venta')
                return
            }
            if (confirm('Esta seguro de guardar?')) {
                store.commit("dialogoprogress")
                var entrada = moment().unix()
                var array = {
                    habitacion: this.data.nombre,
                    fecha_entrada: entrada,
                    fecha_salida: "",
                    numero_cliente: this.numero_cliente,
                    nombre_cliente: this.nombre_cliente,
                    documento_cliente: this.documento_cliente,
                    observacion_cliente: this.observacion_cliente,
                    costo: this.costo,
                    detalle_venta: this.detalle_venta,
                    data_acompañantes: this.arrayintegrantes
                }
                await nueva_habitacion(array.habitacion + '/estado', 'Ocupada')
                await nueva_habitacion(array.habitacion + '/detalle', array.fecha_entrada)
                await guard_registro(array.fecha_entrada, array)
                store.commit("dialogoprogress")
                this.cierra()
            }
        },
        elimina_acom(data) {
            const index = this.arrayintegrantes.indexOf(data);
            if (index > -1) {
                this.arrayintegrantes.splice(index, 1);
            }
        },
        agrega_acom() {
            var id = this.arrayintegrantes.length + 1
            this.arrayintegrantes.push({
                id: id,
                numero_acom: this.numero_acom,
                nombre_acom: this.nombre_acom,
                documento_acom: this.documento_acom,
            })
            this.dialog_agrega_acom = false
            this.numero_acom = ''
            this.nombre_acom = ''
        },
        actualizaTiempo() {
            var hora = moment().unix()
            this.date_hora = moment.unix(hora).format('hh:mm:ss a')
        },
        async BuscarDocumento(tipo) {
            const esacomp = tipo === 1;

            // Selección de variables y verificación de longitud
            const documento = esacomp ? this.documento_cliente : this.documento_acom;
            const numero = esacomp ? this.numero_cliente : this.numero_acom;
            const longitudValida = (documento === "DNI" && numero.length === 8) || (documento === "RUC" && numero.length === 11);

            if (longitudValida) {
                store.commit("dialogoprogress"); // Abre el diálogo de progreso
                const tipoDoc = numero.length === 11 ? "RUC" : "DNI";

                // Actualiza el tipo de documento en función del número
                if (esacomp) this.documento_cliente = tipoDoc;
                else this.documento_acom = tipoDoc;

                // Busca el cliente en el store
                const cliente = store.state.clientes.find(cliente => String(cliente.documento) === String(numero));

                if (cliente) {
                    // Cliente encontrado en la tienda, carga sus datos
                    this.cargarDatos(cliente, esacomp);
                } else {
                    // Cliente no encontrado, realiza consulta a la API
                    const consulta = await this.consultaApiPeru(tipoDoc, numero);
                    this.llenardatos(documento, consulta, esacomp);
                }
            } else {
                store.commit('dialogosnackbar', 'Documento Invalido');
            }
        },

        // Método unificado para cargar datos del cliente en función del tipo (pagador o pasajero)
        cargarDatos(cliente, esacomp) {
            if (esacomp) {
                this.nombre_cliente = cliente.nombre;
            } else {
                this.nombre_acom = cliente.nombre;
            }
            store.commit("dialogoprogress"); // Cierra el diálogo de progreso
        },

        // Método unificado para llenar datos tras consulta API
        llenardatos(documento, data, esacomp) {
            this.guarda_cliente(documento, data)
            if (esacomp) {
                this.nombre_cliente = data.nombre_completo || data.nombre_o_razon_social || '';
            } else {
                this.nombre_acom = data.nombre_completo || '';
            }
        },
        async guarda_cliente(documento, data) {
            var array = {
                activo: true,
                id: data.numero || data.ruc || '',
                tipodoc: documento,
                documento: data.numero || data.ruc || '',
                nombre: data.nombre_completo || data.nombre_o_razon_social || '',
                correo: '',
                departamento: data.departamento || '',
                provincia: data.provincia || '',
                distrito: data.distrito || '',
                ubigeo: data.ubigeo_sunat || '',
                direccion: data.direccion || '',
                telefono: '',
                alias: '',
                nota: 'agregado automaticamente',
                referencia: '',
            }
            await nuevoCliente(array.id, array)
        },
        async consultaApiPeru(documento, numero) {
            const token = '80a4a1c5f2e97c2d78fcd5074cd64ff0a29007ef91880ad2c214af67a084433d';
            try {
                const response = await axios.get(`https://apiperu.dev/api/${documento.toLowerCase()}/${numero}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                });

                if (response.data && response.data.data) {
                    return response.data.data;
                } else {
                    return null;
                }
            } catch (error) {
                store.commit('dialogosnackbar', 'Error al consultar los datos. Inténtalo nuevamente.');
                return null;
            } finally {
                store.commit("dialogoprogress");
            }
        },
        cierra() {
            this.$emit('cierra', false);
        }
    },
};
</script>

<style scoped>
.text-center {
    text-align: center;
}

.group-header {
    background-color: #f5f5f5;
    text-align: left;
    padding: 10px;
}
</style>
