<template>
    <v-dialog v-model="dial" max-width="550" persistent>
        <div>
            <v-system-bar class="" dense window dark height="40">
                <v-icon large color="red" @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <h4>Gestion Habitaciones</h4>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-icon color="green" large @click="guardar_manif()">mdi-content-save</v-icon>
            </v-system-bar>
        </div>

        <v-card class="pa-3">
            <v-row class="pa-2">
                <v-col cols="6">
                    <v-btn color="primary" x-small block dark @click="openDialog(null)"> Crear </v-btn>
                </v-col>
                <v-col cols=" 6">
                    <v-select outlined dense v-model="categoria" :items="categories" label="ZONA"></v-select>
                </v-col>
            </v-row>
            <!-- Tabla de habitaciones -->
            <v-simple-table fixed-header height="400px" dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Nombre
                            </th>
                            <th class="text-left">
                                Tipo
                            </th>
                            <th class="text-left">
                                Categoria
                            </th>
                            <th class="text-left">
                                Accion
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in rooms" :key="item.nombre">
                            <td>{{ item.nombre }}</td>
                            <td>{{ item.tipo }}</td>
                            <td>{{ item.categoria }}</td>
                            <td width="150">
                                <v-row>
                                    <v-col cols="4">
                                        <v-icon color="green" @click="openDialog(item)">mdi-lead-pencil</v-icon>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-icon color="red" @click="deleteRoom(item)">mdi-delete</v-icon>
                                    </v-col>
                                </v-row>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <!-- Diálogo para crear/editar habitaciones -->
            <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                    <v-card-title>{{ dialogRoom ? 'Editar Habitación' : 'Crear Habitación' }}</v-card-title>
                    <v-card-text>
                        <v-form ref="form" v-model="valid">
                            <v-text-field v-model="roomData.nombre" label="Nombre de la Habitación"
                                required></v-text-field>
                            <v-text-field v-model="roomData.tipo" label="Tipo (Simple, Doble, Suite)"
                                required></v-text-field>
                            <v-text-field v-model="roomData.categoria" label="Piso o Categoría (Ej. Piso 1, VIP)"
                                required></v-text-field>
                            <v-text-field type="number" v-model="roomData.costo" label="Costo S/."
                                required></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="blue darken-1" text @click="crear_habitacion">Guardar</v-btn>
                        <v-btn color="grey" text @click="closeDialog">Cancelar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </v-dialog>
</template>

<script>
import store from '@/store/index'
import {
    nueva_habitacion,
    all_habitaciones,
    delete_habitacion
} from '../../db'
export default {
    data() {
        return {
            dial: false,
            rooms: [],
            dialog: false,
            dialogRoom: null,
            roomData: {
                nombre: '',
                tipo: '',
                categoria: '',
                costo: ''
            },
            valid: false,
            categoria: null,
            editar: '',
        };
    },
    mounted() {
        all_habitaciones().on("value", this.onDataChange);
    },
    beforeDestroy() {
        all_habitaciones().off("value", this.onDataChange);
    },
    computed: {
        groupedRooms() {
            const groups = {};
            this.rooms.forEach((room) => {
                if (!groups[room.categoria]) {
                    groups[room.categoria] = {
                        nombre: room.categoria,
                        rooms: []
                    };
                }
                groups[room.categoria].rooms.push(room);
            });
            return Object.values(groups);
        },
        categories() {
            // Devuelve solo las categorías únicas
            return [...new Set(this.rooms.map((room) => room.categoria))];
        },
    },
    created() {
        this.dial = true
    },

    methods: {
        onDataChange(items) {
            this.rooms = []
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                if (data.estado == 'PENDIENTE') {
                    data.color = '#46FF00'
                } else {
                    data.color = '#FF0000'
                }
                array.push(data);
            });
            this.rooms = array;
        },
        // 
        openDialog(room) {
            this.dialogRoom = room;
            if (room) {
                // Editar habitación: cargar datos
                this.roomData = {
                    ...room
                };
            } else {
                // Crear nueva habitación: vaciar datos
                this.roomData = {
                    nombre: '',
                    tipo: '',
                    categoria: '',
                    costo: ''
                };
            }
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
        },
        async crear_habitacion() {
            if (!this.editar && this.rooms.find(item => item.nombre == this.roomData.nombre)) {
                alert('NUMERO DE MESA YA EXISTE')
                return
            }
            store.commit("dialogoprogress")
            var array = {
                nombre: this.roomData.nombre,
                tipo: this.roomData.tipo,
                categoria: this.roomData.categoria,
                costo: this.roomData.costo,
                estado: 'Disponible'
            }
            await nueva_habitacion(array.nombre, array)
            this.roomData.nombre = ''
            this.roomData.tipo = ''
            this.roomData.categoria = ''
            this.roomData.costo = ''
            this.editar = false
            this.dialog = false
            store.commit("dialogoprogress")
        },
        async deleteRoom(room) {
            if (confirm('seguro de eliminar?')) {
                const index = this.rooms.indexOf(room);
                if (index > -1) {
                    this.rooms.splice(index, 1);
                    await delete_habitacion(room.nombre)
                }
            }
        },
        cierra() {
            this.$emit('cierra', false);
        }
    },
};
</script>

<style scoped>
.text-center {
    text-align: center;
}

.group-header {
    background-color: #f5f5f5;
    text-align: left;
    padding: 10px;
}
</style>
