<template>
    <v-dialog v-model="dial" max-width="600px" persistent>
        <div>
            <v-system-bar window dark>
                <v-icon large color="red" @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>

                <v-spacer></v-spacer>
                <v-btn x-small color="success" @click="generarSeriesAutomaticas">
                    <v-icon color="white">mdi-content-save</v-icon>
                    Generar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn x-small color="info" @click="guardar">
                    <v-icon color="white">mdi-content-save</v-icon>
                    Guardar
                </v-btn>
            </v-system-bar>
        </div>
        <v-card class="pa-3">

            <!-- Formulario para agregar una nueva serie -->
            <v-row>
                <v-col cols="6">
                    <v-select outlined dense v-model="tipo" :items="array_tipo.map(item => item.label)"
                        menu-props="auto" hide-details label="Tipo" @change="setSeriePrefix"></v-select>
                </v-col>
                <v-col cols="6">
                    <v-text-field v-model="nuevaSerie" label="Serie (4 dígitos)" maxlength="4" outlined dense
                        @keyup.enter="agregarSerie"></v-text-field>
                </v-col>
                <v-col cols="6" class=" mt-n7">
                    <v-btn block small color="primary" @click="agregarSerie">Agregar</v-btn>
                </v-col>
            </v-row>

            <!-- Tabla para mostrar las series y números generados -->
            <v-simple-table dense>
                <thead>
                    <tr>
                        <th>Tipo</th>
                        <th>Serie</th>
                        <th>Correlativo</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="serie in series" :key="serie.key">
                        <td>{{ serie.tipo }}</td>
                        <td>{{ serie.serie }}</td>
                        <td>{{ serie.correlativo }}</td>
                        <td>
                            <v-btn small icon color="red" @click="eliminarSerie(serie.key)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card>
    </v-dialog>
</template>

<script>
import {
    nueva_serie, all_serie,
    elimina_serie
} from "../../db";
import store from "@/store/index";

export default {
    data() {
        return {
            dial: false,
            nuevaSerie: "",
            series: [],
            tipo: '',
            array_tipo: [
                { label: 'BOLETA', prefix: 'B' },
                { label: 'FACTURA', prefix: 'F' },
                { label: 'NOTA DE CREDITO', prefix: 'B' },
                { label: 'NOTA DE VENTA', prefix: 'T' },
                { label: 'GUIA REMITENTE', prefix: 'T' },
                { label: 'GUIA TRANSPORTISTA', prefix: 'V' },
                { label: 'PROFORMA', prefix: 'P' }
            ]
        };
    },
    async created() {
        all_serie().orderByChild('tipo').on("value", this.onDataChange);
        this.dial = true
    },
    computed: {
        orderedSeries() {

            return this.series.slice().sort((a, b) => a.tipo.localeCompare(b.tipo));
        }
    },
    beforeDestroy() {
        all_serie().off("value", this.onDataChange);
    },

    methods: {
        onDataChange(snap) {
            var array = []
            snap.forEach((item) => {
                let data = item.val();
                data.key = item.key;
                array.push(data);
            });
            this.series = array;
        },
        async guardar() {
            if (!this.series.length) {
                alert("No hay series para guardar.");
                return;
            }
            if (confirm("¿Está seguro de guardar?")) {
                try {
                    store.commit("dialogoprogress", true);
                    for (const serie of this.series) {
                        await nueva_serie(serie);
                    }
                    alert("Series guardadas exitosamente.");
                    this.cierra();
                } catch (error) {
                    console.error("Error al guardar las series:", error);
                    alert("Hubo un error al guardar.");
                } finally {
                    store.commit("dialogoprogress", false);
                }
            }
        },
        agregarSerie() {
            if (this.tipo == '') {
                alert("Debe seleccionar un tipo de serie.");
                return;
            }
            if (this.nuevaSerie.length === 4) {
                const nuevoNumeroGenerado = '00000001';
                this.series.push({
                    tipo: this.tipo,
                    cod_tipo: this.nuevaSerie.toUpperCase().charAt(0),
                    serie: this.nuevaSerie.toUpperCase(),
                    correlativo: nuevoNumeroGenerado,
                });
                this.nuevaSerie = "";
            } else {
                alert("La serie debe tener exactamente 4 dígitos y ser única.");
            }
        },
        async eliminarSerie(serieId) {
            if (confirm('esta seguro de eliminar?')) {
                store.commit("dialogoprogress", false);
                await elimina_serie(serieId)
                store.commit("dialogoprogress", false);
            }

        },
        cierra() {
            this.$emit("cierra");
        },
        setSeriePrefix() {
            const tipoSeleccionado = this.array_tipo.find(t => t.label === this.tipo);
            this.nuevaSerie = tipoSeleccionado ? tipoSeleccionado.prefix : '';
        },
        generarSeriesAutomaticas() {
            this.series = this.array_tipo.map(item => {
                return {
                    tipo: item.label,
                    cod_tipo: item.prefix,
                    serie: `${item.prefix}001`,
                    correlativo: '00000001',
                };
            });
        }
    }
};
</script>

<style scoped>
.series-manager {
    margin: 20px;
}
</style>
